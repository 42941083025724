Ease.extend = {
	textitems: {
		editor: {
			style_formats: [
				{ title: "Link", block: "p", classes: "textitem-link" },
				{
					title: "Button",
					block: "div",
					classes:
						"textitem-button inline-block py-5 px-12 bg-primary-500 hover:bg-primary-450 transition-colors rounded-lg text-lg md:text-xl text-center font-normal w-full md:w-auto mt-2 md:mt-0 cursor-pointer",
				},
			],
			toolbar:
				"undo redo | h2 h3 | bold italic underline | alignleft aligncenter alignright | bullist numlist | link | styleselect | outdent indent | code | blockquote",
		},
	},
	lists: {
		editor: {
			toolbar: "bold italic underline | link",
		},
	},
};

$(function () {
	// Browser checking
	$.reject({
		reject: {
			//msie8: true;
		},
		closeCookie: false,
	});

	// Searchbox
	var $site_search__component = $("#search--component");
	var $site_search__toggle = $site_search__component.find(".search--toggle");
	$site_search__toggle.on("click", function () {
		$site_search__component.toggleClass("search--component--visible");
		if ($site_search__component.hasClass("search--component--visible")) {
			$("#search--searchbox-input").focus();
		}
	});

	// Searchbox autocomplete
	/*var $site_search__searchbox__input = $('#search--searchbox-input');
	$site_search__searchbox__input.autocomplete({
		item_url: '{Url}',
		item_param: '{Parameter}',
		search_url: '',
		results_class: 'search--results',
		results_item_class: 'search--results-item',
		results_insert_reference_element: '.search--searchbox-holder'
	});*/

	// Initialize Slideshows and AddThis
	Ease.initializePlugins();

	// Initialize Offcanvas menu
	Offcanvas.initialize();

	// Initiliaze Dotdotdot's
	try {
		$(".dotdotdot").dotdotdot({
			watch: "window",
		});
		setTimeout(function () {
			$(".dotdotdot").trigger("destroy").dotdotdot({
				watch: "window",
			});
		}, 3000);
	} catch (e) {}

	// Alertbox close buttons
	$("alertbox > .close").on("click", function (e) {
		e.preventDefault();
		var $this = $(this);
		var target = $this.attr("data-target");
		$(this)
			.closest(target ? target : "grid")
			.slideUp(function () {
				$(this).remove();
			});
	});

	// Convert all # anchor tags to smooth scrolling
	$('a[href*="#"]:not([href="#"])').click(function () {
		if (
			location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") &&
			location.hostname === this.hostname
		) {
			var target = $(this.hash);
			target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
			if (target.length) {
				$("html, body").animate(
					{
						scrollTop: target.offset().top,
					},
					1000
				);
				return false;
			}
		}
	});

	// Fullscreenator
	$(window).on("resize load", function () {
		$(".fullscreenator").css({
			width: $(window).width(),
			height: Ease.isLoggedIn ? $(window).height() - 48 : $(window).height(),
		});
	});

	// Remove loader
	$(window).on("load", function () {
		$("#loader_spinner").fadeOut(300, function () {
			$("#loader_holder").fadeOut(300);
		});
	});

	$(".adgroup--lysing iframe").on("load", function () {
		try {
			$(this)
				.contents()
				.find("head")
				.append(
					$('<style type="text/css">canvas { width: 100% !important; height: 100% !important; }</style>')
				);
		} catch (ex) {}
	});

	// Iframe lazyloader
	$("iframe[data-src]").each(function () {
		var $this = $(this);
		$this.attr("src", $this.attr("data-src")).removeAttr("data-src");
	});
});

/**
 * Initialize a newsgroup lazy loader (for use with multiple newsgroup componenets)
 * @param limit
 */
var lazyLoader2_scrollBusy = false;
var lazyLoader2 = function (limit) {
	limit = typeof limit !== "undefined" && limit ? limit : Tools.getQueryVariable("_Limit");
	var $newsgroup_show_more = $("#newsgroup_show_more");
	$newsgroup_show_more.on("click", function () {
		var $newsgroup_show_more = $("#newsgroup_show_more");
		$newsgroup_show_more.addClass("busy");
		var newsgroupIds = $newsgroup_show_more.attr("data-ease-newsgroupids");
		var $ease_newsgroup = $(".newsgroup--self").last();
		$ease_newsgroup.attr("data-ease-buttonselector", "#newsgroup_show_more");
		var language = $ease_newsgroup.attr("data-ease-language");
		var excludenewsarticleid = $ease_newsgroup.attr("data-ease-excludenewsarticleid");
		var offset = parseInt($ease_newsgroup.attr("data-ease-offset"), 10);
		var interval = parseInt($ease_newsgroup.attr("data-ease-interval"), 10);
		var template = $ease_newsgroup.attr("data-ease-template");
		var search = $ease_newsgroup.attr("data-ease-search");
		var $ease_newsarticles = $ease_newsgroup.find(".lazyRoot").not(".add");
		var items = $ease_newsarticles.length;
		$.get(
			"/page_default.php",
			{
				Offset: offset,
				Limit: items,
				Interval: interval,
				NewsgroupIds: newsgroupIds,
				Search: search,
				p: "newsgroup_lazy",
				t: template,
				Language: language,
				ExcludeNewsarticleId: excludenewsarticleid,
				Append: true,
			},
			function (html) {
				var $html = $(html).find(".lazyRoot");
				//$html = $html.length === 0 ? $(html) : $html;
				var $ease_newsgroup = $(".newsgroup--self");
				var previousCount = $ease_newsgroup.find(".lazyRoot").length;
				$ease_newsgroup.find(".lazyRoot").last().after($html);
				Ease.overlay && Ease.overlay.initialize($ease_newsgroup);
				Ease.initializePlugins();
				$ease_newsarticles = $ease_newsgroup.find(".lazyRoot").not(".add");
				$ease_newsgroup.attr("data-ease-limit", $ease_newsarticles.length);
				history.replaceState(null, "", "?" + Tools.getQuery("_Limit") + "&_Limit=" + $ease_newsarticles.length);
				$newsgroup_show_more.removeClass("busy");
				Revealator.refresh();
				var currentCount = $ease_newsgroup.find(".lazyRoot").length;
				if (previousCount + interval > currentCount) {
					$("#newsgroup_show_more").hide();
					lazyLoader2_scrollBusy = true;
				} else {
					lazyLoader2_scrollBusy = false;
				}
			}
		);
	});

	// Auto activate lazyLoader2 on scroll to bottom
	$(window).on("scroll resize load ready", function () {
		if (!lazyLoader2_scrollBusy) {
			lazyLoader2_scrollBusy = true;
			setTimeout(function () {
				var $newsgroup_show_more = $("#newsgroup_show_more");
				if ($(window).scrollTop() + $(window).height() > $(document).height() - 400) {
					$newsgroup_show_more.click();
				} else {
					lazyLoader2_scrollBusy = false;
				}
			}, 100);
		}
	});

	window.onpopstate = function () {
		var $newsgroup_show_more = $("#newsgroup_show_more");
		var newsgroupIds = $newsgroup_show_more.attr("data-ease-newsgroupids");
		var $ease_newsgroup = $("#ease_newsgroup_" + newsgroupIds);
		var offset = parseInt($ease_newsgroup.attr("data-ease-offset"), 10);
		var interval = parseInt($ease_newsgroup.attr("data-ease-interval"), 10);
		$ease_newsgroup.attr(
			"data-ease-limit",
			Tools.getQueryVariable("_Limit") ? Tools.getQueryVariable("_Limit") : interval
		);
		Ease.newsgroups && Ease.newsgroups.onchange();
	};

	var $ease_newsarticles = $(".newsgroup--component").find(".lazyRoot").not(".add");
	if ($ease_newsarticles.length < limit) {
		$newsgroup_show_more.hide();
	}
};
